import request from '@/utils/request'

// 员工列表
export function salaryList(data) {
  return request({
    url: 'api/v2/salary/eaEmployeeList',
    method: 'post',
    data
  })
}

// 员工新增资产
export function saveEmployees(data) {
  return request({
    url: 'api/v2/salary/saveEmployees',
    method: 'post',
    data
  })
}

// 员工删除资产
export function delEaEmployee(data) {
  return request({
    url: 'api/v2/salary/delEaEmployee',
    method: 'post',
    data
  })
}
//新增工资表
export function saveEaSalarySheet(data) {
  return request({
    url: 'api/v2/salary/saveEaSalarySheet',
    method: 'post',
    data
  })
}
//工资表列表
export function salarySheetList(data) {
  return request({
    url: 'api/v2/salary/salarySheetList',
    method: 'post',
    data
  })
}
//工资表删除
export function delEaSalarySheet(data) {
  return request({
    url: 'api/v2/salary/delEaSalarySheet',
    method: 'post',
    data
  })
}
// 正常工资薪资可添加员工列表
export function salaryEmpList(data) {
  return request({
    url: 'api/v2/salary/salaryEmpList',
    method: 'post',
    data
  })
}
//正常工资薪资批量新增新增员
export function saveEaSalary(data) {
  return request({
    url: 'api/v2/salary/saveEaSalary',
    method: 'post',
    data
  })
}
//正常工资薪资员工薪酬列表
export function eaSalaryList(data) {
  return request({
    url: 'api/v2/salary/salaryList',
    method: 'post',
    data
  })
}
//个人所得添加人员
export function saveEaSalaryAddGr(data) {
  return request({
    url: 'api/v2/salary/saveEaSalaryAddGr',
    method: 'post',
    data
  })
}
//个人所得股东列表
export function getEaSalaryOnes(data) {
  return request({
    url: 'api/v2/salary/getEaSalaryOnes',
    method: 'post',
    data
  })
}
//个人所得股东列表批量修改
export function saveEaSalaryOneAll(data) {
  return request({
    url: 'api/v2/salary/saveEaSalaryOneAll',
    method: 'post',
    data
  })
}
//个人所得股东列表删除员工
export function deleteEaSalaryOne(data) {
  return request({
    url: 'api/v2/salary/deleteEaSalaryOne',
    method: 'post',
    data
  })
}
//个人所得自动取值
export function salayAutomaticValue(data) {
  return request({
    url: 'api/v2/salary/salayAutomaticValue',
    method: 'post',
    data
  })
}
//个人所得获取取值详情
export function salarySheetInfo(data) {
  return request({
    url: 'api/v2/salary/salarySheetInfo',
    method: 'post',
    data
  })
}
//个人所得获取取值详情修改
export function salarySheetEdit(data) {
  return request({
    url: 'api/v2/salary/salarySheetEdit',
    method: 'post',
    data
  })
}
//累计数据保存
export function salaryInitImport(data) {
  return request({
    url: 'api/v2/salary/salaryInitImport',
    method: 'post',
    data
  })
}
//累计数据列表
export function getEaSalayPeriod(data) {
  return request({
    url: 'api/v2/salary/getEaSalayPeriod',
    method: 'post',
    data
  })
}
//累计数据批量删除
export function salaryInitDel(data) {
  return request({
    url: 'api/v2/salary/salaryInitDel',
    method: 'post',
    data
  })
}

//复制往期薪酬
export function oeClickReplicationOne(data) {
  return request({
    url: 'api/v2/salary/oeClickReplicationOne',
    method: 'post',
    data
  })
}

//批量删除
export function delSalary(data) {
  return request({
    url: 'api/v2/salary/delSalary',
    method: 'post',
    data
  })
}

export function saveEaSalaryRemuneration(data) {
  return request({
    url: 'api/v2/salary/saveEaSalaryRemuneration',
    method: 'post',
    data
  })
}

export function salaryRemunerationList(data) {
  return request({
    url: 'api/v2/salary/salaryRemunerationList',
    method: 'post',
    data
  })
}

export function delSalaryRem(data) {
  return request({
    url: 'api/v2/salary/delSalaryRem',
    method: 'post',
    data
  })
}

export function saveEaSalaryOnly(data) {
  return request({
    url: 'api/v2/salary/saveEaSalaryOnly',
    method: 'post',
    data
  })
}

export function salaryOnlyList(data) {
  return request({
    url: 'api/v2/salary/salaryOnlyList',
    method: 'post',
    data
  })
}

export function delSalaryOnly(data) {
  return request({
    url: 'api/v2/salary/delSalaryOnly',
    method: 'post',
    data
  })
}

export function salaryOneJmSave(data) {
  return request({
    url: 'api/v2/salary/salaryOneJmSave',
    method: 'post',
    data
  })
}

export function salaryOneJmList(data) {
  return request({
    url: 'api/v2/salary/salaryOneJmList',
    method: 'post',
    data
  })
}

// 批量复制往期薪酬
export function oneClickReplicationArr(data) {
  return request({
    url: 'api/v2/salary/oneClickReplicationArr',
    method: 'post',
    data
  })
}